// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projectpages-gamewebsite-js": () => import("./../../../src/pages/projectpages/gamewebsite.js" /* webpackChunkName: "component---src-pages-projectpages-gamewebsite-js" */),
  "component---src-pages-projectpages-quizwebsite-js": () => import("./../../../src/pages/projectpages/quizwebsite.js" /* webpackChunkName: "component---src-pages-projectpages-quizwebsite-js" */),
  "component---src-pages-projectpages-resumewebsite-js": () => import("./../../../src/pages/projectpages/resumewebsite.js" /* webpackChunkName: "component---src-pages-projectpages-resumewebsite-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

